import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance, postRequest } from "./api";
import { APIBASEURL, apiUrls } from "../constants";
import { Fragment } from "react";
import UAParser from "ua-parser-js";
import { useLocation, useNavigate } from "react-router-dom";

function AuthChecker(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const login = useSelector((state) => state.auth.login);

  const AuthRoutes = ["/login-register"];

  const PrivateRoutes = ["/my-account"];

  const addAnalytic = async () => {
    const ip = localStorage.getItem("userIP");
    const country = localStorage.getItem("userLocation");
    const parser = new UAParser();
    const result = parser.getResult();
    const visit = {
      page: window.location.pathname,
      ip: ip,
      referrer: document.referrer || "Default",
      os: result.os.name,
      browser: result.browser.name,
      country: country,
    };
    const response = await axiosInstance.post(
      APIBASEURL + "/analytics/track-page-visit",
      visit
    );
  };

  useEffect(() => {
    addAnalytic();
    if (AuthRoutes.includes(location.pathname) && login == true) {
      navigate("/");
    } else if (PrivateRoutes.includes(location.pathname) && login == false) {
      navigate("/login-register");
    } else {
      return;
    }
  }, [location, login]);

  return <Fragment>{props.children}</Fragment>;
}

export default AuthChecker;
