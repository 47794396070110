import { useEffect, useState } from "react";

export const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(() => {
    if (typeof window !== "undefined") {
      return window.matchMedia("(max-width: 768px)").matches;
    }
    return false;
  });

  useEffect(() => {
    const checkScreenSize = () => {
      if (typeof window !== "undefined") {
        const isScreenSizeMobile =
          window.matchMedia("(max-width: 768px)").matches;
        setIsMobile(isScreenSizeMobile);
      }
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return isMobile;
};
