import React from "react";
import cogoToast from "cogo-toast";

const CustomToast = ({ name, product, location, imageUrl }) => (
  <div className="minimal-toast d-flex align-items-center">
    <div className="toast-avatar-div">
      <img className="toast-avatar" src={imageUrl} alt="avatar" />
    </div>
    <div className="toast-content">
      <p className="toast-title mb-0">
        <strong>{name}</strong> purchased <strong>{product}</strong>
      </p>
      <p className="toast-location mb-0">from {location}</p>
    </div>
  </div>
);

export const showCustomToast = (name, product, location, imageUrl) => {
  cogoToast.info(
    <CustomToast
      name={name}
      product={product}
      location={location}
      imageUrl={imageUrl}
    />,
    {
      position: "top-left",
      hideAfter: 5, // Auto hide after 5 seconds
      heading: false, // No heading for a cleaner look
      icon: false, // Disable the default info icon
    }
  );
};
