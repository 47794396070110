import { v4 as uuidv4 } from "uuid";
import cogoToast from "cogo-toast";
const { createSlice } = require("@reduxjs/toolkit");

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
  },
  reducers: {
    addToCart(state, action) {
      const product = action.payload;

      if (!product.variants?.length > 0) {
        const cartItem = state.cartItems.find(
          (item) => item._id === product._id
        );
        if (!cartItem) {
          state.cartItems.push({
            ...product,
            orderedQuantity: product.orderedQuantity
              ? product.orderedQuantity
              : 1,
            cartItemId: uuidv4(),
          });
        } else {
          state.cartItems = state.cartItems.map((item) => {
            if (item.cartItemId === cartItem.cartItemId) {
              return {
                ...item,
                orderedQuantity: product.orderedQuantity
                  ? item.orderedQuantity + product.orderedQuantity
                  : item.orderedQuantity + 1,
              };
            }
            return item;
          });
        }
      } else {
        const cartItem = state.cartItems.find(
          (item) =>
            item._id === product._id &&
            item.selectedProductVariant?._id ==
              product.selectedProductVariant?._id
        );
        if (!cartItem) {
          state.cartItems.push({
            ...product,
            orderedQuantity: product.orderedQuantity
              ? product.orderedQuantity
              : 1,
            cartItemId: uuidv4(),
          });
        }
        // else if (
        //   cartItem !== undefined &&
        //   cartItem.selectedProductVariant !== product.selectedProductVariant
        // ) {
        //   state.cartItems = [
        //     ...state.cartItems,
        //     {
        //       ...product,
        //       orderedQuantity: product.orderedQuantity
        //         ? product.orderedQuantity
        //         : 1,
        //       cartItemId: uuidv4(),
        //     },
        //   ];
        // }
        else {
          state.cartItems = state.cartItems.map((item) => {
            if (item.cartItemId === cartItem.cartItemId) {
              return {
                ...item,
                orderedQuantity: product.orderedQuantity
                  ? item.orderedQuantity + product.orderedQuantity
                  : item.orderedQuantity + 1,
                selectedProductVariant: product.selectedProductVariant,
              };
            }
            return item;
          });
        }
      }

      cogoToast.success("Added To Cart", { position: "bottom-left" });
    },
    deleteFromCart(state, action) {
      state.cartItems = state.cartItems.filter(
        (item) => item.cartItemId !== action.payload
      );
      cogoToast.error("Removed From Cart", { position: "bottom-left" });
    },
    decreaseQuantity(state, action) {
      const product = action.payload;
      if (product.orderedQuantity === 1) {
        state.cartItems = state.cartItems.filter(
          (item) => item.cartItemId !== product.cartItemId
        );
        cogoToast.error("Removed From Cart", { position: "bottom-left" });
      } else {
        state.cartItems = state.cartItems.map((item) =>
          item.cartItemId === product.cartItemId
            ? { ...item, orderedQuantity: item.orderedQuantity - 1 }
            : item
        );
        cogoToast.warn("Item Decremented From Cart", {
          position: "bottom-left",
        });
      }
    },
    deleteAllFromCart(state) {
      state.cartItems = [];
    },
  },
});

export const {
  addToCart,
  deleteFromCart,
  decreaseQuantity,
  deleteAllFromCart,
} = cartSlice.actions;
export default cartSlice.reducer;
