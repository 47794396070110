import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    login: false,
    user: null,
    userIP: "",
  },
  reducers: {
    handleLogin: (state, action) => {
      state.login = action.payload.login;
      state.user = action.payload.user;
    },
    handleLogout: (state, action) => {
      state.login = false;
      state.user = null;
      localStorage.setItem("token", "");
    },
    handleUser: (state, action) => {
      state.user = action.payload.user;
    },
    setUserIP: (state, action) => {
      state.userIP = action.payload;
    },
  },
});

export const { handleLogin, handleLogout, handleUser, setUserIP } =
  authSlice.actions;

export default authSlice.reducer;
