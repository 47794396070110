import axios from "axios";
import dayjs from "dayjs";

export const calculatePriceAfterDiscount = (price, discount) => {
  const actualPrice = parseInt(price);
  const actualDiscount = parseInt(discount);
  if (actualDiscount > 0) {
    const discountAmount = (actualPrice * actualDiscount) / 100;
    const discountedPrice = actualPrice - discountAmount;
    return discountedPrice.toFixed(2);
  } else {
    return actualPrice.toFixed(2);
  }
};

export const truncateString = (string, length) => {
  let result = "";
  const stringLength = string.length;
  if (stringLength > length) {
    result = `${string.slice(0, length)}...`;
    return result;
  } else {
    result = string;
    return result;
  }
};

const calculateHash = async (input) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);

  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");

  return hashHex;
};

function getUnixTimeStamp(date) {
  let now;
  if (date) {
    now = new Date(date);
  } else {
    now = new Date();
  }
  const unixTimestampSeconds = Math.floor(now.getTime() / 1000);
  return unixTimestampSeconds;
}

function generateBrowserId() {
  const creationTime = getUnixTimeStamp();
  const randomNumber = Math.floor(Math.random() * 1000000);
  return `fb.${1}.${creationTime}.${randomNumber}`;
}

function generateFbc() {
  const fbclid = localStorage.getItem("fbclid");
  const creationTime = getUnixTimeStamp();

  return `fb.${1}.${creationTime}.${fbclid}`;
}

function generateEventId(eventName) {
  const eventId = `${eventName}-${dayjs().valueOf()}`;
  return eventId;
}

export const sendEventToFacebook = async (
  eventName,
  userEmail,
  userPhone,
  purchaseValue,
  contentId,
  contentType
) => {
  const pixelIDWsGlamWebsite = "7053991538053983";
  const pixelIDWsGlamWebsite2 = "1133470134491188";
  const userIP = localStorage.getItem("userIP");
  const fbclid = localStorage.getItem("fbclid");
  const browserId = generateBrowserId();
  const accessTokenWsGlamWebsite =
    "EAAEtY50ZB4t8BOZCZBU8HnncuxX5QbDmDz2zxMZBimkHjtcoV7ZB6QffnG4stYESBpC3sA8CaA7e6D22I1M1AgO2pDvWcJEItzXU4k5X01HrGZAaZA9WfKCvrMN3NGKpQP911OftRPRcNmpTFf1ccFn5EQumYru3tvGUFdjwz0qJb4lbXZCXp59rt5rVGokh1jeaGAZDZD";
  const accessTokenWsGlamWebsite2 =
    "EAAEtY50ZB4t8BOZBXzbmGARJ5zPXPlJYwkHTMGGZBdyZBN2q6jZAmZCtz8jaCNqyOEJAwXFeWibCzp0tZCkHQS4O2ACVnozezpparNPZCws3ApgEi2nBGgw3S3vj60IsvpcmBApJ6KWTVThrUklHrrDzPth4BJWiUhqaTwj1ZBRqZBcHZAhlu9j9QwZAV82LLZArAIZAFR3AZDZD";

  try {
    let hashedEmail = null;
    if (userEmail.trim() !== "") {
      hashedEmail = await calculateHash(userEmail);
    }
    let hashedPhone = null;
    if (userPhone.trim() !== "") {
      hashedPhone = await calculateHash(userPhone);
    }

    const eventData = {
      data: [
        {
          action_source: "website",
          event_name: eventName,
          event_time: Math.floor(Date.now() / 1000),
          event_id: generateEventId(eventName),
          event_source_url: window.location.href,
          user_data: {
            em: hashedEmail,
            ph: hashedPhone,
            client_ip_address: userIP,
            client_user_agent: window.navigator.userAgent,
            ...(fbclid != "null" ? { fbc: generateFbc() } : {}),
            fbp: browserId,
          },
          custom_data: {
            currency: "PKR",
            value: purchaseValue,
            ...(contentId ? { content_ids: contentId } : {}),
            ...(contentType ? { content_type: contentType } : {}),
          },
        },
      ],
    };

    //Sending event to ws glam website dataset
    const response = await axios.post(
      `https://graph.facebook.com/v12.0/${pixelIDWsGlamWebsite}/events?access_token=${accessTokenWsGlamWebsite}`,
      eventData
    );

    //Sending event to ws glam website 2 dataset
    const response2 = await axios.post(
      `https://graph.facebook.com/v12.0/${pixelIDWsGlamWebsite2}/events?access_token=${accessTokenWsGlamWebsite2}`,
      eventData
    );
  } catch (error) {
    console.error("Error sending event to Facebook:", error);
    return error;
  }
};

export const sendSnapchatEvent = async (
  eventName,
  userEmail,
  userPhone,
  purchaseValue,
  contentIds,
  contentCategory,
  currency = "USD"
) => {
  try {
    let hashedEmail = null;
    if (userEmail.trim() !== "") {
      hashedEmail = calculateHash(userEmail);
    }
    let hashedPhone = null;
    if (userPhone.trim() !== "") {
      hashedPhone = calculateHash(userPhone);
    }

    // Get the _scid cookie value
    const scid = document.cookie
      .split("; ")
      .find((row) => row.startsWith("_scid="))
      .split("=")[1];

    const eventData = {
      price: purchaseValue ? purchaseValue / 280 : null,
      currency: currency,
      item_ids: contentIds,
      item_category: contentCategory,
      uuid_c1: scid, // Use _scid value for uuid_c1
      user_hashed_email: hashedEmail,
      user_hashed_phone_number: hashedPhone,
    };

    // Send the event using snaptr
    if (window.snaptr) {
      window.snaptr("track", eventName, eventData);
      console.log(`Snapchat event ${eventName} sent successfully`);
    } else {
      console.error("Snap Pixel not initialized");
    }
  } catch (error) {
    console.log(error);
  }
};
