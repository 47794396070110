import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../helpers/api";
import { APIBASEURL, apiUrls } from "../constants";
import { handleLogin, handleLogout } from "../store/slices/auth-slice";
import axios from "axios";
import { sendEventToFacebook } from "../util/util";
import { Spinner } from "reactstrap";
import LoaderScreen from "../components/custom-components/LoaderScreen"

function AuthWrapper(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const fbclid = urlParams.get("fbclid");
    localStorage.setItem("fbclid", fbclid);
  }, []);

  const checkAuth = async () => {
    const token = localStorage.getItem("token");
    if (token != "" && token != null) {
      try {
        const response = await postRequest(apiUrls.signInUser, {
          firebaseToken: token,
        });
        if (response.data.success == true) {
          const payload = {
            user: response.data.result,
            login: true,
          };
          dispatch(handleLogin(payload));
          setLoading(false);
        } else {
          dispatch(handleLogout());
          setLoading(false);
        }
      } catch (error) {
        dispatch(handleLogout());
        setLoading(false);
      }
    } else {
      dispatch(handleLogout());
      setLoading(false);
    }
  };

  const getUserIp = async () => {
    setLoading(true);
    await axios
      .get("https://ipinfo.io/json?token=1d1cac9ccf4058")
      .then((response) => {
        localStorage.setItem("userLocation", `${response.data.country}`);
        localStorage.setItem("userLoc", response.data.loc);
      })
      .catch((error) => {
        localStorage.setItem("userLocation", "");
        localStorage.setItem("userLoc", "");
      });

    await axios
      .get(APIBASEURL + "/getIp")
      .then((response) => {
        localStorage.setItem("userIP", response.data.ip);
      })
      .catch((error) => {});

    setLoading(false);
  };

  useEffect(() => {
    getUserIp();
    checkAuth();
  }, []);

  const user = useSelector((state) => state.auth.user);

  

  return (
    <>
      {loading ? (
        <LoaderScreen />
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </>
  );
}

export default AuthWrapper;
