import React from "react";
import { Spinner } from "reactstrap";
import { useDeviceDetect } from "../../util/hooks";
import { BeatLoader } from "react-spinners";

function LoaderScreen() {
  const isMobile = useDeviceDetect();

  return (
    <div
      style={{
        height: "100vh",
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <div className="text-center">
        <img
          style={{
            height: isMobile ? 80 : 120,
            width: "auto",
          }}
          src={"/assets/img/logo/logo.png"}
        />
        <br />
        <BeatLoader color="#fa50ac" size={isMobile ? 15 : 20} />
      </div>
    </div>
  );
}

export default LoaderScreen;
