import React, { useContext, useEffect } from "react";
import io from "socket.io-client";
import { APIBASEURL } from "../constants/index";
import { showCustomToast } from "../components/custom-components/CustomOrderToast";

const SocketContext = React.createContext();
export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const socket = io(APIBASEURL);

  useEffect(() => {
    // Emit event when the socket connects
    socket.emit("get-in-online-users");

    // Handle the user deciding to close the tab or navigate away
    const handleUnload = () => {
      socket.emit("get-out-from-online-users");
    };

    // Additional handler for visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        handleUnload();
      } else {
        socket.emit("get-in-online-users");
      }
    };

    // Listen for "new order notification" from the server
    socket.on("new order notification", (data) => {
      const { productName, personName, city, productImage } = data;

      // Trigger the custom toast notification
      showCustomToast(personName, productName, city, productImage);
    });

    // Setup event listeners for beforeunload and visibilitychange
    window.addEventListener("beforeunload", handleUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // Cleanup event listeners and emit disconnect when component unmounts
      window.removeEventListener("beforeunload", handleUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      socket.emit("get-out-from-online-users");
      socket.disconnect();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};
